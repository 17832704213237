.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 0.8rem;
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid var(--input-border);
  position: relative;
  background: transparent;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 0.8rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row .Token-text {
  font-size: var(--font-md);
  color: var(--fgd-1);
  font-weight: 500;
  letter-spacing: 0px;
}

.TokenSelector-token-row:hover {
  @apply bg-th-bkg-3;
}

.TokenSelector-token-row:active {
  background: var(--dark-blue-active);
}

.text-accent {
  color: var(--dark-blue-accent);
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
}

@media (max-width: 700px) {
  .Token-info .token-logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.8rem;
  }
}
