.performance-calendar .profit-tile {
  @apply graph-success border !border-th-up-dark;
}

.performance-calendar .loss-tile {
  @apply graph-error border !border-th-down-dark;
  border: 1px solid var(--down-dark) !important;
}

.performance-calendar .not-current-month {
  opacity: 0.5;
}

.performance-calendar .react-calendar__month-view__days__day {
  cursor: default !important;
}

.performance-calendar .react-calendar__tile {
  width: 48px !important;
  height: 48px !important;
  border: 1px solid var(--input-border);
  flex: none !important;
  position: relative;
}

.performance-calendar .react-calendar__tile abbr {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #9e9e9e;
  position: absolute;
  right: 4px;
  top: 2px;
}

.performance-calendar .react-calendar__month-view__days,
.performance-calendar .react-calendar__month-view__weekdays {
  gap: 4px;
}

.performance-calendar .react-calendar__month-view__weekdays__weekday {
  width: 48px !important;
  flex: none !important;
  padding: 16px 0;
  padding-top: 10px;
}

.performance-calendar .react-calendar__month-view__weekdays__weekday {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--fgd-3);
}

.performance-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.performance-calendar .react-calendar__navigation__label__labelText {
  font-size: 16px;
  color: var(--fgd-3);
}

.performance-calendar .react-calendar__navigation {
  /* display: flex; */
}

@media screen and (min-width: 640px) {
  .performance-calendar .react-calendar__month-view__days__day {
    cursor: default !important;
  }

  .performance-calendar .react-calendar__tile {
    width: 60px !important;
    height: 60px !important;
    border: 1px solid var(--input-border);
    flex: none !important;
    position: relative;
  }

  .performance-calendar .react-calendar__month-view__days,
  .performance-calendar .react-calendar__month-view__weekdays {
    gap: 8px;
  }

  .performance-calendar .react-calendar__month-view__weekdays__weekday {
    width: 60px !important;
    flex: none !important;
    padding: 16px 0;
    padding-top: 10px;
  }
}

.react-calendar__year-view__months {
  @apply gap-[8px];
}
