.Tooltip {
  position: relative;
}

.Tooltip-popup {
  @apply  bg-th-bkg-2;
  font-size: var(--font-sm);
  font-weight: normal;
  line-height: 1.6rem;
  position: absolute;
  border-radius: 4px;
  padding: 1.05rem;
  letter-spacing: 0.4px;
  width: fit-content;
  min-width: 28rem;
  max-width: 35rem;
  text-align: left;
  white-space: normal;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  z-index: 10000;

  a {
    color: var(--fgd-3);
    text-decoration: underline;
  }

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
    position: absolute;
    display: none;
  }
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  transform: translateY(0.8rem);
  top: 100%;

  &::after {
    display: block;
    top: -4px;
    background: #232741;
  }
}

.Tooltip-popup.right-bottom {
  right: 0;
  transform: translateY(0.8rem);
  top: 100%;

  &::after {
    display: block;
    top: -4px;
    right: 8px;
    background: #2e3351;
  }
}

.Tooltip-popup.right-top {
  right: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;

  &::after {
    display: block;
    bottom: -4px;
    right: 8px;
    background: #2e3351;
  }
}
.Tooltip-popup.right {
  left: 3rem;
  transform: translateY(50%);
  bottom: 100%;

  &::after {
    display: block;
    left: -4px;
    top: 50%;
    background: #252740;
  }
}

.Tooltip-popup.left-top {
  left: 0;
  transform: translateY(-0.8rem);
  bottom: 100%;

  &::after {
    display: block;
    bottom: -4px;
    background: #252740;
  }
}

.Tooltip-popup.center-bottom {
  left: 50%;
  transform: translateY(0.8rem) translateX(-50%);
  top: 100%;

  &::after {
    display: block;
    background: #292d49;
    left: 50%;
    top: -4px;
  }
}

.Tooltip-popup.center-top {
  left: 50%;
  transform: translateY(-0.8rem) translateX(-50%);
  bottom: 100%;

  &::after {
    display: block;
    background: #292d49;
    left: 50%;
    bottom: -4px;
  }
}

.Tooltip-handle {
  z-index: 1;
  cursor: help;
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.6);
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip-error {
  .Tooltip-handle {
    color: var(--error-red);
    text-decoration-color: var(--error-red);
  }
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

.Tooltip-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.45);
  margin: 0.5rem 0;
}

.Tooltip-row {
  display: grid;
  margin: 0 0 0.5rem 0;
  grid-template-columns: 1fr auto;
}

.Tooltip-row > span.label {
  margin-right: 0.5rem;
}

.Tooltip-row-values {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tooltip-row-values li:not(:first-child) {
  padding: 0.25rem 0 0 0;
  text-align: right;
}

.Tooltip-number {
  color: white;
}

.Tooltip-flex {
  display: flex;
  flex-direction: column;
}

.Tooltip-disabled-wrapper {
  pointer-events: none;
  display: flex;
  flex: 0 0 100%;
}

.z-index-1001 {
  z-index: 1001;
}

@media (max-width: 700px) {
  .Tooltip-row {
    display: block;
    margin-bottom: 1rem;
  }
  .Tooltip-row > span.label {
    display: block;
  }
}
