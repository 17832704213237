/* Range Picker */

.nice-dates .nice-dates-popover {
  @apply z-20 max-w-[384px] bg-th-bkg-4;
}

.nice-dates .nice-dates-navigation {
  @apply font-bold text-th-fgd-1;
}

.nice-dates .nice-dates-navigation_next:before {
  @apply border-th-fgd-2 hover:border-th-active;
}

.nice-dates .nice-dates-navigation_next:hover:before {
  @apply border-th-active;
}

.nice-dates .nice-dates-navigation_previous:before {
  @apply border-th-fgd-2 hover:border-th-active;
}

.nice-dates .nice-dates-navigation_previous:hover:before {
  @apply border-th-active;
}

.nice-dates .nice-dates-week-header_day {
  @apply text-th-fgd-2;
}

.nice-dates .nice-dates-day_date {
  @apply text-th-fgd-3;
}

.nice-dates .-outside .nice-dates-day_date {
  @apply text-th-fgd-3;
}

.nice-dates .nice-dates-day_month {
  @apply text-th-fgd-3;
}

.nice-dates .nice-dates-day:after {
  @apply border-th-fgd-3;
}

.nice-dates .nice-dates-day:before {
  @apply bg-th-bkg-1;
}

.nice-dates .nice-dates-day.-selected:hover:after {
  @apply bg-th-bkg-3;
}

/* react-date picker */
.react-datepicker-popper {
  z-index: 50 !important;
}

.react-datepicker__day--keyboard-selected {
  background: none !important;
}

.react-datepicker,
.react-datepicker__header {
  background: transparent !important;
  border: none !important;
  padding-left: 24px;
  padding-right: 24px;
}

.react-datepicker {
  border: 1px solid var(--input-border) !important;
}

.react-datepicker {
  padding-bottom: 70px;
}

.react-datepicker__current-month {
  font-size: 12px !important;
  color: var(--fgd-1) !important;
  font-weight: 700 !important;
  padding-bottom: 12px !important;
  border-bottom: 1px solid var(--input-border) !important;
  margin-bottom: 8px !important;
  padding-top: 12px;
}

.react-datepicker__day-name {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: var(--fgd-3) !important;
  width: 36px !important;
  text-align: center !important;
  margin: 0 !important;
}

.react-datepicker__day {
  font-size: 12px !important;
  color: var(--fgd-1) !important;
  font-weight: 500 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  width: 36px !important;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range {
  color: rgba(255, 255, 255, 0.4) !important;
  background: rgba(255, 255, 255, 0.05) !important;
}

.react-datepicker__day.react-datepicker__day--range-start,
.react-datepicker__day.react-datepicker__day--selecting-range-start {
  background: var(--fgd-1) !important;
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  color: #000 !important;
}

.react-datepicker__day.react-datepicker__day--range-end,
.react-datepicker__day.react-datepicker__day--selecting-range-end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  color: #000 !important;
  background: var(--fgd-1) !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--outside-month,
.react-datepicker__day--disabled {
  color: var(--fgd-3) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__navigation {
  top: 20px !important;
}

.react-datepicker__day:hover[aria-disabled='false'] {
  color: #000 !important;
  background-color: var(--fgd-1) !important;
}
/* end react-date picker */
