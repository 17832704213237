.SyntheticsStats {
  margin: 20px;
  text-align: left;
}

.SyntheticsStats .download-csv {
  height: 100px;
  margin-bottom: 35px;
}

.SyntheticsStats-table-wrap {
  overflow-x: auto;
  margin-bottom: 100px;
}

.SyntheticsStats-table-wrap .sticky-left {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  background: var(--main-bg-color);
}

.sticky-left {
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  background: var(--main-bg-color);
}

.sticky-left:hover {
  @apply !bg-th-bkg-3 z-40;
}

.SyntheticsStats .csv-link {
  margin-top: 20px;
}

.SyntheticsStats td,
.SyntheticsStats th {
  padding: 7px 10px;
  position: relative;
  vertical-align: top;
  white-space: nowrap;
}

.SyntheticsStats .cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.synthetics-cell {
  width: 120px; 
}
